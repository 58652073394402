<template>
  <section :class="$style.article">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <div :class="$style.wrapper">
        <el-button type="primary" @click="submitForm('form')">
          Создать
        </el-button>
      </div>
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Название ссылки" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Фото статьи" prop="images">
        <Uploader
          :limit="1"
          :files="form.images"
          @upload="uploadImages"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Категории" prop="categories">
        <el-select
          :class="$style.categories"
          v-model="form.categories"
          multiple
          filterable
          default-first-option
          placeholder="Выберите категории"
        >
          <el-option
            v-for="item in categoriesOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Текст кнопки" prop="caption">
        <el-input v-model="form.redirectAction.caption"></el-input>
      </el-form-item>
      <el-form-item label="Ссылка кнопки" prop="linkTo">
        <el-input v-model="form.redirectAction.linkTo"></el-input>
      </el-form-item>
      <el-form-item label="Тип кнопки" prop="type">
        <el-input v-model="form.redirectAction.type"></el-input>
      </el-form-item>
      <el-form-item label="Дата публикации" prop="publishedAt">
        <el-date-picker
          v-model="form.publishedAt"
          type="date"
          :class="$style.date"
          placeholder="Выберите дату"
          :picker-options="datePickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Отображать на сайте">
        <el-checkbox v-model="form.isActive" />
      </el-form-item>
      <el-form-item label="Описание" prop="content" />
      <TextEditor :value.sync="form.content" />
      <PhotoGallery
        :photos.sync="form.gallery.photos"
        :direction.sync="form.gallery.direction"
        :margin.sync="form.gallery.margin"
        :columns.sync="form.gallery.columns"
        :target-row-height.sync="form.gallery.targetRowHeight"
        :limit.sync="form.gallery.limit"
      />
      <SeoBlock
        style="margin-top: 2rem"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import SeoBlock from '@/components/moleculs/SEO.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import PhotoGallery from '@/components/moleculs/PhotoGallery.vue'

import { transliterate } from '@/helpers/slug'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

export default {
  components: {
    TextEditor,
    SeoBlock,
    Uploader,
    PhotoGallery,
  },
  ADDWINE_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      categoriesOptions: [],
      form: {
        images: [],
        name: '',
        slug: '',
        category: '',
        categories: [],
        content: '',
        isActive: false,
        publishedAt: '',
        redirectAction: {
          linkTo: '',
          caption: '',
          type: '',
        },
        gallery: {
          targetRowHeight: 300,
          columns: 1,
          margin: 0,
          direction: 'row',
          limit: 0,
          photos: [],
        },
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date()
        },
      },
      rules: {
        images: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        category: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        content: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        publishedAt: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        categories: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  created() {
    this.getCategoriesOptions()
  },
  methods: {
    async getCategoriesOptions() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.ArticleCategoriesActions.getList()
      if (error) return
      this.categoriesOptions = value.data
      loading.close()
    },

    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    uploadImages(images) {
      this.form.images = images
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          let data = { ...this.form }
          if (
            !data.redirectAction.type ||
            !data.redirectAction.linkTo ||
            !data.redirectAction.caption
          ) {
            data.redirectAction = null
          }
          if (data.gallery.photos.length) {
            data.gallery = {
              ...data.gallery,
              photos: data.gallery.photos?.map((photo) => ({
                id: photo.id,
                src: photo.original,
                width: photo.width,
                height: photo.height,
              })),
            }
          }

          const result = await delivery.ContentServiceCore.BlogArticles.create(
            data,
          )

          loading.close()

          if (result.error) return

          this.showNotification('Стать успешно создана', 'success')

          this.$router.push(ADDWINE_ADMIN_ROUTES.BLOG.ARTICLES.LIST)
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.article {
  padding: 1rem;
  .form {
    .wrapper {
      @include stickyWrapper;
      a {
        padding: 0 0.625rem;
        button {
          height: 100%;
        }
      }
    }
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }

    .date {
      width: 100%;
    }
    .categories {
      width: 100%;
    }
  }
}
</style>
